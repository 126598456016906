import _camelCase from 'lodash.camelcase';
import { objectToQueryString } from 'utils/httpsUtils';
import { getOneTrustOffice } from 'utils/oneTrust';
import { base64Encode } from 'utils/string';
import { CURRENCY_BY_COUNTRY } from './COUNTRIES';
import { TAP_API_BASE_URL } from './ENV';
import ROUTES from './ROUTES';

const BASE_PATH = '/travel-advisor';

const API_PATHS = Object.freeze({
  agent: ({ email }) => `agent/${base64Encode(email?.toLowerCase() || '')}`,
  agentAcademy: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/academy`,
  agentBookings: ({ emailBase64, pageCount, agentRole }) =>
    `agent/${emailBase64}/bookings${objectToQueryString({ agentRole, pageCount })}`,
  agentCreate: () => 'agent',
  agentRewards: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/rewards`,
  cms: async ({ path, office }) => {
    office = office || (await getOneTrustOffice());
    return `cms/${office}/${path}`;
  },
  cobrandedUrlExists: (urlBase64) => `cobranded/url/${urlBase64}`,
  cruises: ({ office, voyageName, simple = true }) =>
    `cms/${office}/cruises?voyageName=${voyageName}${simple ? '&simple=true' : ''}`,
  flags: () => 'flags',
  getValidAgency: ({ agencyIata, email }) =>
    `agency/${agencyIata}${email ? `?emailBase64=${base64Encode(email.toLowerCase())}` : ''}`,
  marketing: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/marketingcenter`,
  myJourneyAuth: ({ accountId }) => `mvj/auth/${accountId}`,
  offerCode: ({ currency, office, offerCode, voyageNameBase64 }) =>
    `offerCodes/${office}/${currency}/${voyageNameBase64}/${offerCode}`,
  onlineBooking: ({ currency, office, payloadBase64 }) => `olb/${office}/${currency}/${payloadBase64}`,
  search: ({ currency, offerCode }) => `search/${currency}${offerCode ? `?offerCode=${offerCode}` : ''}`,
  sendInvoice: ({ office, currency, bookingId, invoiceType }) =>
    `invoice/${office}/${currency}/${bookingId}/${invoiceType}`,
  ships: ({ shipId }) => `ships/${shipId}`,
  shipStateroomCategories: ({ shipId }) => `ships/stateroomCategories/${shipId}`,
  staterooms: ({ currency, office, voyageId, offerCode }) => {
    return `staterooms/${office}/${currency || CURRENCY_BY_COUNTRY[office].code}/${voyageId}?offerCode=${offerCode}`;
  },
  validateAddress: ({ country }) => `address/${(country || '').toUpperCase()}/validate`,
  versionInfo: () => 'versioninfo',
  voyages: ({ currency, offerCode, office, voyageNameBase64 }) => {
    return `voyages/${office}/${
      currency || CURRENCY_BY_COUNTRY[office].code
    }/${voyageNameBase64}/all?offerCode=${offerCode}`;
  },
  website: (email) => `cobranded/${base64Encode(email?.toLowerCase() || '')}`,
  websiteSave: () => 'cobranded',
});

const CMS_PATHS = Object.freeze({
  contacts: { key: 'contacts', path: 'contacts' },
  faq: { key: 'faq', path: 'faq' },
  pages: {
    common: { key: 'common', path: 'pages/common' },
    homePageModal: { key: 'hp-modal', path: 'pages/hp-modal' },
    ...Object.keys(ROUTES).reduce((acc, key) => {
      acc[key] = { key: _camelCase(`pages-${key}`), path: `pages/${ROUTES[key]?.key || key}` };
      return acc;
    }, {}),
  },
});

const CMS_REDIRECT_URL_KEYS = Object.freeze({
  academy: '[API_PATH]/agent/academy',
  rewards: '[API_PATH]/agent/rewards',
  marketing: '[API_PATH]/agent/marketingcenter',
});

const HTTP_VERBS = Object.freeze({
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
});

const UNHANDLED_ENDPOINTS = ['validateAddress'];

export { API_PATHS, BASE_PATH, CMS_PATHS, CMS_REDIRECT_URL_KEYS, HTTP_VERBS, UNHANDLED_ENDPOINTS };
