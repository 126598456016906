import { OFFICE_BY_COUNTRY } from 'constants/COUNTRIES';
import { ENVIRONMENT_CODE } from 'constants/ENV';

import { keyify } from 'utils/string';

const ANALYTICS_EVENT_TYPES = Object.freeze({
  ACCOUNT_CREATION: 'account_creation',
  BANNER_CLICK: 'banner_click',
  BANNER_NEXT: 'banner_next',
  BANNER_PREVIOUS: 'banner_prev',
  BUTTON_CLICK: 'cta_button_click',
  DP_FILTER: 'dp_filter',
  FAC_FILTER: 'fac_filter',
  FOOTER_CLICK: 'footer_link_click',
  FORM_START: 'form_start',
  MAIN_NAVIGATION: 'main_navigation',
  PAGE_INIT: 'page_data_initialized',
  PAGE_VIEW: 'spa_page_view',
  SEARCH: 'internal_site_search',
  TILE_CLICK: 'tile_clicked',
});

const ANALYTICS_LINK_TYPES = Object.freeze({
  BUTTON: 'button',
  TEXT: 'text',
  PHOTO: 'photo',
  LINK: 'link',
  BANNER: 'banner',
  CHECKBOX: 'checkbox',
});

const initializeTagData = (initialData, country, isRefresh) => {
  const tagData = initialData || {};
  const adobeEvent = cleanData({
    ...tagData,
    acdl_event: ANALYTICS_EVENT_TYPES.PAGE_INIT,
    domain_country_code: OFFICE_BY_COUNTRY[country] || 'US',
    environment_code: ENVIRONMENT_CODE || '',
    event: ANALYTICS_EVENT_TYPES.PAGE_INIT,
    refresh: isRefresh,
    site_code: 'tap',
  });

  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const triggerPageView = ({ path = '/', pageId, pageName, isLoggedIn, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    ...attributes,
    acdl_event: 'view',
    event: ANALYTICS_EVENT_TYPES.PAGE_VIEW,
    login_status: `${isLoggedIn || 'false'}`,
    page_id: pageId,
    page_name: pageName || '',
    page: path || '',
  };
  delete eventData.isLoggedIn;
  delete eventData.pageId;
  delete eventData.pageName;

  fireViewEvent(eventData);
};

const getAnalyticsState = (stateProperty) => {
  if (!window.adobeDataLayer) {
    window.adobeDataLayer = [];
  } else if (typeof window.adobeDataLayer === 'object' && typeof window.adobeDataLayer.getState === 'function') {
    return window.adobeDataLayer.getState(stateProperty);
  }

  return '';
};

const triggerLinkEvent = ({ linkText, linkType = ANALYTICS_LINK_TYPES.LINK, linkId, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    acdl_event: 'link',
    cta_name: keyify(linkText),
    element_name: linkId,
    link_id: keyify(linkId),
    link_type: linkType,
    page_id: getAnalyticsState('page_id') || keyify(window.document?.title) || '',
    page_name: getAnalyticsState('page_name') || keyify(window.document?.title) || '',

    ...attributes,
  };
  fireLinkEvent(eventData);
};

// Private Functions //
const fireAdobeEvent = (tagData) => {
  const adobeEvent = cleanData({
    ...tagData,
  });

  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const fireAdobeTargetView = (tagData) => {
  window?.adobe?.target?.triggerView(tagData.page_id);
};

const fireViewEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
  fireAdobeTargetView(tagData);
};

const fireLinkEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const cleanData = (data) =>
  Object.keys(data)
    .sort()
    .reduce((acc, key) => {
      if (!!data[key] || [0, '0'].includes(data[key])) {
        return { ...acc, [key]: data[key] };
      }
      return acc;
    }, {});

export { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, initializeTagData, triggerLinkEvent, triggerPageView };
