import classNames from 'classnames';
import Content from 'common/a11y/Content';
import PropTypes from 'prop-types';
import './Error.scss';

const ErrorPrimitive = ({ children, className, description, title }) => {
  if (!children && !description && !title) {
    throw new Error('Error component requires one of the following: children, description, and title.');
  }

  return (
    <div className={classNames('text-center', className)}>
      {title && <h2>{title}</h2>}
      <Content className="error-content" config={{ html: description }} type="p" />
      {children}
    </div>
  );
};

ErrorPrimitive.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

export default ErrorPrimitive;
