import IMAGE_FALLBACK from './IMAGE_FALLBACK';

const IMAGE_ASPECT_RATIOS = Object.freeze({
  FIVE_BY_FOUR: '5x4',
  FIVE_BY_TWO: '5x2',
  FOUR_BY_FIVE: '4x5',
  FOUR_BY_ONE: '4x1',
  NINE_BY_SIXTEEN: '9x16',
  ONE_BY_ONE: '1x1',
  SIXTEEN_BY_NINE: '16x9',
  THREE_BY_TWO: '3x2',
  TWO_BY_ONE: '2x1',
  TWO_BY_THREE: '2x3',
});

const IMAGE_CMS_ASSET_BASE_URL = 'https://aem-prod-publish.viking.com/content/dam/vikingcruises/en/magnolia-images/';
const IMAGE_FALLBACK_CSS_CLASS = 'img-error';
const IMAGE_HANDLER_BASE_URL = process.env.REACT_APP_IMAGE_HANDLER_BASE_URL;
const IMAGE_NONE = '/no-image.jpg';
const IMAGE_VIKING_LOGO_COLOR = `${IMAGE_CMS_ASSET_BASE_URL}logo/VIKING_COLOR.svg`;
const IMAGE_VIKING_LOGO_WHITE_HORIZONTAL = `${IMAGE_CMS_ASSET_BASE_URL}site_elements/VIKING_WHITE_Horiz.svg`;
const IMAGE_VIKING_LOGO_WHITE = `${IMAGE_CMS_ASSET_BASE_URL}/site_elements/vikinglogowhite.svg`;

export {
  IMAGE_ASPECT_RATIOS,
  IMAGE_CMS_ASSET_BASE_URL,
  IMAGE_FALLBACK,
  IMAGE_FALLBACK_CSS_CLASS,
  IMAGE_HANDLER_BASE_URL,
  IMAGE_NONE,
  IMAGE_VIKING_LOGO_COLOR,
  IMAGE_VIKING_LOGO_WHITE,
  IMAGE_VIKING_LOGO_WHITE_HORIZONTAL,
};
