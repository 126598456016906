import { setIsRedirectModalEnabled, setRedirectPage } from 'app/slices/redirect/redirectSlice';
import { CMS_REDIRECT_URL_KEYS } from 'constants/API';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import RedirectLink from './RedirectLink';

const RedirectContext = createContext({});

const RedirectProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [academyRefs, setAcademyRefs] = useState();
  const [marketingRefs, setMarketingRefs] = useState();
  const [rewardsRefs, setRewardsRefs] = useState();

  const value = useMemo(
    () => ({
      onAcademyClick: () => academyRefs?.buttonRef?.current?.click(),
      onMarketingClick: () => marketingRefs?.buttonRef?.current?.click(),
      onRewardsClick: () => rewardsRefs?.buttonRef?.current?.click(),
      onSetRedirectPage: (payload) => dispatch(setRedirectPage(payload)),
      onSetRedirectModalEnabled: (isEnabled) => dispatch(setIsRedirectModalEnabled(isEnabled)),
    }),
    [academyRefs, dispatch, marketingRefs, rewardsRefs]
  );

  return (
    <RedirectContext.Provider value={value}>
      {children}
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.academy} isHidden onGetRefs={setAcademyRefs} />
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.marketing} isHidden onGetRefs={setMarketingRefs} />
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.rewards} isHidden onGetRefs={setRewardsRefs} />
    </RedirectContext.Provider>
  );
};

RedirectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedirectProvider;

export { RedirectContext };
