/* eslint-disable react/jsx-filename-extension */
import livePerson from '@viking-eng/live-person';
import AnalyticsSite from 'common/analytics/AnalyticsSite';
import RootError from 'common/error/RootError';
import CommonWrapper from 'common/layout/CommonWrapper';
import PageLoading from 'common/pageLoading/PageLoading';
import { initialize } from 'hooks/useAuth';
import { init as mockInit } from 'mocks/browser';
import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import 'utils/appInsights';
import { logError } from 'utils/logging';
import { createStore } from './app/store';

const App = lazy(() => import('app/App'));
const CookiesCheck = lazy(() => import('common/cookiesCheck/CookiesCheck'));

const { persistor, store } = createStore();

(async () => {
  const msalInstance = await initialize();
  await mockInit();

  if (!livePerson) {
    // eslint-disable-next-line no-console
    console.warn('LivePerson is not available.');
  }

  createRoot(document.getElementById('root')).render(
    <ReactErrorBoundary FallbackComponent={RootError} onError={(error, info) => logError('App', { error, info })}>
      <StrictMode>
        <AnalyticsSite>
          <Suspense fallback={null}>
            <CookiesCheck>
              <Provider store={store}>
                <PersistGate loading={<PageLoading />} persistor={persistor}>
                  <CommonWrapper>
                    <App msalInstance={msalInstance} store={store} />
                  </CommonWrapper>
                </PersistGate>
              </Provider>
            </CookiesCheck>
          </Suspense>
        </AnalyticsSite>
      </StrictMode>
    </ReactErrorBoundary>
  );
})();
