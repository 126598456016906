// IMPORTANT: Use only for cases that involve special cases or network complications.
// CMS, tapStrings or tapProperties should be the first place to consider before adding here.

const AUTH_BUTTON_TEXT = Object.freeze({
  SIGN_IN: 'Sign In',
  SIGN_OUT: 'Sign Out',
});

const MYJOURNEY_PATH = '/myjourney';

const OLB_DISABLED_FALLBACK =
  'Making Bookings is currently down for maintenance and will be back soon. ' +
  'You can still search itineraries and view dates.';

const PAGE_ERROR_DESCRIPTION =
  // eslint-disable-next-line max-len
  "We apologize for the inconvenience, but it seems like the page you're trying to access is currently unavailable. Our team is aware of the issue and is working to resolve it. Please try again later.";

const SITE_NAME = 'Travel Advisor Portal';

const SPECIAL_OFFER_AVAILABLE = 'Special Offer Available';

const TRAVEL_ADVISOR = 'Travel Advisor';

export {
  AUTH_BUTTON_TEXT,
  MYJOURNEY_PATH,
  OLB_DISABLED_FALLBACK,
  PAGE_ERROR_DESCRIPTION,
  SITE_NAME,
  SPECIAL_OFFER_AVAILABLE,
  TRAVEL_ADVISOR,
};
