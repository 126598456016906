import {
  BrowserUtils,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import api from 'app/services/tap/api';
import { clearAuth, setAuth } from 'app/slices/auth/authSlice';
import {
  AUTH_ERROR_CODES,
  AUTH_LOGIN_MAX_AUTO_TRIES,
  AUTH_USER_TYPES,
  B2C_AUTH_ERROR_CODES,
} from 'common/auth/AUTH_CONSTANTS';
import NavigationClient from 'common/auth/NavigationClient';
import { loginRequest as authConfigLoginRequest, b2cPolicies, msalConfig } from 'configs/authConfig';
import { B2C_DOMAIN } from 'constants/ENV';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import ROUTES from 'constants/ROUTES';
import { MYJOURNEY_PATH } from 'constants/STRINGS';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllCookies } from 'utils/cookies';
import { addLoader, removeLoaderWithTimer } from 'utils/loader';
import { localLogger, logError } from 'utils/logging';
import useNavigate from './useNavigate';

const initialize = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  // Ensures account is set ASAP. More detailed event logic handled in handleEvents
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.enableAccountStorageEvents();
  const eventCallback = (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      msalInstance.setActiveAccount(account);
    }
    msalInstance.removeEventCallback(eventCallback);
  };
  msalInstance.addEventCallback(eventCallback);
  return msalInstance;
};

const useAuth = () => {
  const { inProgress, instance: msalInstance } = useMsal();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginTries = useRef(0);

  const changeEmail = async () => {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changeUserName);
  };

  const changePassword = async () => {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
  };

  const getAccount = () => {
    return msalInstance.getActiveAccount() || msalInstance.getAllAccounts()?.[0];
  };

  const getIsRouteAuthorized = ({ offices }) => (offices.length ? offices.includes(auth.agency.office) : false);

  const getLoginTries = () => loginTries.current;

  const getToken = async ({ authority, callingFunction = '' } = {}) => {
    localLogger(`useAuth.js - getToken from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    const request = {
      ...msalConfig,
      account: auth.msal.account,
    };
    if (authority) {
      request.authority = authority;
    }
    const token = await msalInstance.acquireTokenSilent(request);
    return token;
  };

  const handleEvents = async ({ handleAccountRemoved = () => null }) => {
    msalInstance.addEventCallback(async (event) => {
      if (event.error) {
        logError('AUTH ERROR', JSON.stringify(event));
        if (
          event.error instanceof InteractionRequiredAuthError ||
          (event.error.errorCode === AUTH_ERROR_CODES.ACCESS_DENIED &&
            event.error.errorMessage.includes(B2C_AUTH_ERROR_CODES.CANCEL_ACTION))
        ) {
          await login({ callingFunction: 'useAuth.js - handleEvents 1', loginRequest: msalConfig });
        } else if (
          event.error.errorCode === AUTH_ERROR_CODES.INVALID_GRANT &&
          event.error.errorMessage.includes(B2C_AUTH_ERROR_CODES.INCORRECT_POLICY)
        ) {
          const matches = event.error.errorMessage.match(/Expected Value : (B2C_[a-zA-Z0-9_]*)/i);
          if (matches.length > 1) {
            const requestedPolicy = matches[1].toUpperCase();
            const authorityWithPolicy = Object.values(b2cPolicies.authorities).find((authority) =>
              authority.authority.toUpperCase().includes(requestedPolicy)
            );
            const passwordFlowRequest = { ...msalConfig, authority: authorityWithPolicy?.authority };
            await login({ callingFunction: 'useAuth.js - handleEvents 2', loginRequest: passwordFlowRequest });
          }
        } else {
          await login({ callingFunction: 'useAuth.js - handleEvents 3', loginRequest: msalConfig });
        }
      } else if (event.eventType === EventType.ACCOUNT_ADDED) {
        // Handled elsewhere
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        handleAccountRemoved();
        await logout({ callingFunction: 'useAuth.jsx - handleEvents 1' });
      } else if (
        [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS].includes(
          event.eventType
        )
      ) {
        const loginAccount = event?.payload?.account || {};
        if (loginAccount?.idTokenClaims?.UserType && loginAccount?.idTokenClaims?.UserType !== AUTH_USER_TYPES.TA) {
          window.location.replace(MYJOURNEY_PATH);
        }
        if (loginAccount) {
          msalInstance.setActiveAccount(loginAccount);
        }
      }
    });
    const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()?.[0];
    msalInstance.setActiveAccount(account);
    if (account) {
      if (account.environment !== B2C_DOMAIN) {
        await logout({ callingFunction: 'useAuth.jsx - handleEvents - 2' });
      }
    }
  };

  const integrateRouting = () => {
    const navigationClient = new NavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
  };

  const login = async ({
    callingFunction = '',
    isPreCheckRequired = true,
    loginRequest = authConfigLoginRequest,
  } = {}) => {
    localLogger(`useAuth.js - Logging in from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    if (isPreCheckRequired) {
      const isMaxLoginReached = loginTries.current >= AUTH_LOGIN_MAX_AUTO_TRIES;
      loginTries.current += 1;
      if (!isMaxLoginReached) {
        if (inProgress === InteractionStatus.None) {
          await msalInstance.handleRedirectPromise();
          const account = msalInstance.getActiveAccount();
          if (!account) {
            await msalInstance.loginRedirect(loginRequest);
          }
        }
      } else {
        loginTries.current = 0;
        await logout({ callingFunction: 'useAuth.js - login 1' });
      }
    } else if (inProgress === InteractionStatus.None) {
      await msalInstance.loginRedirect(loginRequest);
    } else {
      loginTries.current = 0;
      await logout({ callingFunction: 'useAuth.js - login 2' });
    }
  };

  const logout = async ({ callingFunction = '' } = {}) => {
    localLogger(`useAuth.js - Logging out from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    addLoader();
    try {
      dispatch(clearAuth());
      dispatch(api.util.resetApiState());
      removeAllCookies();
      localStorage.clear();
      localStorage.setItem('logout-event', 'started');
      await msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
        onRedirectNavigate: () => {
          return !BrowserUtils.isInIframe();
        },
      });
      await msalInstance.clearCache();
    } catch (error) {
      logError('useAuth - logout', { error });
      navigate(ROUTES.maintenance.url);
    } finally {
      await removeLoaderWithTimer();
    }
  };

  const updateAgent = (agent) => {
    dispatch(setAuth({ agent, callingFunction: 'useAuth.js - updateAgent' }));
  };

  return {
    changeEmail,
    changePassword,
    getAccount,
    getIsRouteAuthorized,
    getLoginTries,
    getToken,
    handleEvents,
    integrateRouting,
    login,
    logout,
    updateAgent,
  };
};

export default useAuth;

export { initialize };
