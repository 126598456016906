import { TAP_API_BASE_URL } from 'constants/ENV';
import { CMS_PAGES_PATHS_ENABLED, CMS_PATHS_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { EVO_COUNTRY } from 'mocks/data/cms/evoCountry';
import { HttpResponse, delay, http } from 'msw';
import { fixMockCMSData, getCommonData } from 'utils/mock';

const handlers = [];

Object.entries(CMS_PATHS_ENABLED).forEach(([office, officePaths]) => {
  Object.entries(officePaths).forEach(([key, value]) => {
    if (value.isEnabled) {
      const url = `${TAP_API_BASE_URL}/cms/${office}/${value.cmsPath.path}`;
      handlers.push(
        http.get(url, async () => {
          let formattedJson = {};
          const json = await import(`mocks/data/cms/tap.cms.${key}.${key}`);
          formattedJson = fixMockCMSData(json.results, office);
          await delay(DELAY);
          return HttpResponse.json(formattedJson);
        })
      );
    }
  });
});

Object.entries(CMS_PAGES_PATHS_ENABLED).forEach(([office, officePaths]) => {
  Object.entries(officePaths).forEach(([key, value]) => {
    if (value.isEnabled) {
      const url = `${TAP_API_BASE_URL}/cms/${office}/${value.cmsPath.path}`;
      handlers.push(
        http.get(url, async () => {
          let formattedJson = {};
          if (key === 'common') {
            const promises = [
              await import('mocks/data/cms/tap.cms.pages.common.json'),
              await import('mocks/data/cms/token.json'),
              await import('mocks/data/properties/tapProperties.json'),
              await import('mocks/data/cms/tapShipCategories.json'),
              await import('mocks/data/properties/tapStrings.json'),
            ];
            const [commonData, tokenData, propsData, shipCategories, stringsData] = await Promise.all(promises);
            formattedJson = getCommonData({
              commonData: commonData.results[0],
              evoCountry: EVO_COUNTRY,
              officeCode: office,
              propsData,
              shipCategories,
              stringsData,
              tokenData: tokenData.results[0],
            });
          }
          await delay(DELAY);
          return HttpResponse.json(formattedJson);
        })
      );
    }
  });
});

export default handlers;
